/**
 * Hier kommt übergreifender Code für folgende Mitarbeiterstammdaten hin
 * - Personalien
 * - Beschaeftigung
 * - Abgaben
 * - Lohnarten & Nettobezüge
 * - Zuschläge
 * - Historie
 */

import _ from 'underscore';

import stammdatenService from '../services/stammdatenService.js';
import {
    debounce,
    erstelleMitarbeiterListe,
    resetValidationMessages,
} from '../util.js';

const debounceSpeichereMA = debounce(async (args) => {
    await stammdatenService.updateMitarbeiterdaten(args[0]);
    // validierung entsprechend des Mitarbeiter Reiters
    switch (window.myVars.websiteStatus) {
        case 'ma-personalien':
            validatePersonalien(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-beschaeftigung':
            validateBeschaeftigung(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-zuschlaege':
            validateZuschlaege(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-abgaben':
            validateAbgaben(stammdatenService.aktuellerMitarbeiter);
            break;
        case 'ma-zeitkonto':
            validateZeitkonto(stammdatenService.aktuellerMitarbeiter);
            break;
        default:
            break;
    }
    await erstelleMitarbeiterListe(stammdatenService.aktuelleBetriebsstaette);
}, 1000);

/**
 * Speichert die aktuellen Mitarbeiterdaten, falls es eine Änderung gibt.
 */
async function forceUpdateMA() {
	// Falls es Änderungen im Mitarbeiter Objekt gibt, müssen wir erst speichern und den debounce entfernen...
	if (window.myVars.mitarbeiterEditiert && !_.isEmpty(stammdatenService.aktuellerMitarbeiter)) {
		await stammdatenService.updateMitarbeiterdaten(stammdatenService.aktuellerMitarbeiter);
		clearTimeout(window.myVars.lastDebounceId);
	}
}

function validateZeitkonto(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Zeitkonto');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

function validateAbgaben(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Abgaben');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText += error.message + '\n';
        }
    });
    const besch = aktuellerMA.Beschaeftigung[0];
    htmlParent.querySelector('#empty-rvNr').style.display = besch.Sozialversicherung.Rentenversicherungsnummer === '' ? 'flex' : 'none';
    htmlParent.querySelector('#empty-steuerID').style.display = besch.Steuer.SteuerIdentifikationsnummer === '' ? 'flex' : 'none';
}


function validatePersonalien(aktuellerMA) {
    const htmlDiv = document.getElementById('ma-Personalien');
    resetValidationMessages();
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlDiv.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
    htmlDiv.querySelector('#empty-geburtsort').style.display = aktuellerMA.Personalien.Geburtsort === '' ? 'flex' : 'none';
}

function validateBeschaeftigung(aktuellerMA) {
    resetValidationMessages();
    const htmlParent = document.getElementById('ma-Beschaeftigung');
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        // Für die Regelarbeitszeiten müssen wir 2x7 messages zeigen...
        if (error?.instancePath?.includes('Regelarbeitszeiten')) {
            const htmlElement = htmlParent.querySelector('[aria-label="/Beschaeftigung/0/Regelarbeitszeiten"]');
            htmlElement.innerText = error.message;
            return;
        }
        if (error?.instancePath?.includes('Verguetung/')) {
            const htmlElement = htmlParent.querySelector(`[aria-label="/Beschaeftigung/0/Verguetung/__index__/${error.instancePath.split('/')[5]}"]`);
            if (htmlElement) {
                htmlElement.innerText = error.message;
                return;
            }
        }
        const htmlElement = htmlParent.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

function validateZuschlaege(aktuellerMA) {
    const htmlDiv = document.getElementById('ma-Zuschlaege');
    resetValidationMessages();
    const validateResults = aktuellerMA.Errors;
    validateResults.forEach((error) => {
        const htmlElement = htmlDiv.querySelector(`[aria-label="${error.instancePath}"]`);
        if (htmlElement) {
            htmlElement.innerText = error.message;
        }
    });
}

export {
    forceUpdateMA,
    debounceSpeichereMA,
    validatePersonalien,
    validateBeschaeftigung,
    validateZeitkonto,
    validateAbgaben,
    validateZuschlaege
};
