function checkLastVisitedOnLogin() {
    const status = localStorage.getItem('app-status');
    const lastUrl = localStorage.getItem('lastVisited');
    // Falls wir neu gestartet bzw. eingeloggt haben, laden wir die zuletzt besuchte Seite, falls sie abweicht von der aktuellen
    if (status === 'false' && lastUrl !== window.location.href) {
        localStorage.setItem('app-status', 'true');
        window.location.replace(lastUrl);
        return;
    }
    localStorage.setItem('app-status', 'true');
}

function storeLastVistiedOnLogout() {
    localStorage.setItem('app-status', 'false');
    localStorage.setItem('lastVisited', window.location.href);
}

function loadColors() {
	const primaryColor = localStorage.getItem('primaryColor');
	const secondaryColor = localStorage.getItem('secondaryColor');
	const thirdColor = localStorage.getItem('thirdColor');
	const fieldColor = localStorage.getItem('fieldColor');
	const field2Color = localStorage.getItem('field2Color');
	const root = document.querySelector(':root');
	if (primaryColor) root.style.setProperty('--primary-color', primaryColor);
	else localStorage.setItem('primaryColor', '#00548f');
	if (secondaryColor) root.style.setProperty('--secondary-color', secondaryColor);
	else localStorage.setItem('secondaryColor', '#0071bd');
	if (thirdColor) root.style.setProperty('--third-color', thirdColor);
	else localStorage.setItem('thirdColor', '#d9eaf5');
	if (fieldColor) root.style.setProperty('--background-grey', fieldColor);
	else localStorage.setItem('fieldColor', '#ececec');
	if (field2Color) root.style.setProperty('--background-grey2', field2Color);
	else localStorage.setItem('field2Color', '#a3acbd');
}

export {
    checkLastVisitedOnLogin,
    storeLastVistiedOnLogout,
    loadColors
};
