import _ from 'underscore';
import stammdatenService from '../services/stammdatenService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { collectValues, displayValues, fuelleSelectOptionen } from '../util.js';
import { updateDebounceUnternehmen } from './verwaltung.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import schichtService from '../services/schichtService.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.selectPausenmodell = selectPausenmodell;
window.myHandlers.pausenmodellAddPause = pausenmodellAddPause;
window.myHandlers.pausenmodellRemovePause = pausenmodellRemovePause;
window.myHandlers.speicherePausenmodell = speicherePausenmodell;
window.myHandlers.erstellePausenmodell = erstellePausenmodell;
window.myHandlers.urlaubssperreNeu = urlaubssperreNeu;
window.myHandlers.confirmUrlaubssperreDialog = confirmUrlaubssperreDialog;
window.myHandlers.entferneUrlaubssperre = entferneUrlaubssperre;
window.myHandlers.updateE2NKey = updateE2NKey;
window.myHandlers.importZeitenE2N = importZeitenE2N;
window.myHandlers.speichereGZEinstellungen = speichereGZEinstellungen;

async function ladeZeitregeln(unternehmen) {
	await ladePausenmodelle(unternehmen);
	await ladeUrlaubssperren(unternehmen);
	await ladeZeitenimport(unternehmen);
	displayValues(unternehmen, '', document.getElementById('u-Gutzeitregeln'), 'u-');
}

async function ladePausenmodelle(unternehmen) {
	const select = document.querySelector('[aria-label="u-input-Pausenmodelle"]');
	fuelleSelectOptionen(select, unternehmen.Pausenmodelle, '_id', 'Bezeichnung', true, 'Pausenmodell wählen');
	// wir wählen automatisch das erste Element aus, falls vorhanden.
	select.value = unternehmen.Pausenmodelle.length > 0 ? unternehmen.Pausenmodelle[0]._id : '';
	selectPausenmodell(select.value);
}

/**
 * Lädt das ausgewählte Pausenmodell in die Oberfläche.
 * @param {string} value ausgewähltes Pausenmodell (id)
 */
function selectPausenmodell(value) {
	// select auf übergebene value setzen, falls von außerhalb getriggert.
	document.querySelector('[aria-label="u-input-Pausenmodelle"]').value = value;
	const unternehmen = stammdatenService.unternehmen;
	const pausenModellFieldset = document.getElementById('u-Pausenmodelle');
	const selectedPausenmodell = unternehmen.Pausenmodelle.find((pm) => pm._id === value);
	const pausenListe = pausenModellFieldset.querySelector('#pausenmodell-pausen');
	[...pausenListe.querySelectorAll('.pausen-liste')].forEach((element) => element.remove());
	pausenModellFieldset.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value = '';
	if (!_.isEmpty(selectedPausenmodell)) {
		const template = document.querySelector('[u-input-pausen-template]');
		pausenModellFieldset.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value = selectedPausenmodell.Bezeichnung;
		selectedPausenmodell.Pausenstandard.forEach((pause) => {
			const newRow = template.content.cloneNode(true).children[0];
			displayValues(pause, '', newRow, 'u-input-pausen-');
			pausenListe.appendChild(newRow);
		});
	}
}

/**
 * Erstellt neue Zeile im Pausenmodell
 */
function pausenmodellAddPause() {
	const template = document.querySelector('[u-input-pausen-template]');
	const pausenListe = document.querySelector('#pausenmodell-pausen');
	const newRow = template.content.cloneNode(true).children[0];
	pausenListe.appendChild(newRow);
	setTimeout(() => newRow.querySelector('input').focus(), 100);
}

/**
 * Entfernt die ausgewählte Pause
 * @param {HTMLElement} thisElement Enternen Icon auf der Pausenzeile
 */
function pausenmodellRemovePause(thisElement) {
	// der Parent des Icons ist die Pausen Zeile, wir entfernen diese und speichern das Pausenmodell
	thisElement.parentNode.remove();
	speicherePausenmodell();
}

/**
 * Speichert das gewählte Pausenmodell im Unternehmen ab.
 */
async function speicherePausenmodell() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const pausenmodellSelect = document.querySelector('[aria-label="u-input-Pausenmodelle"]');
	if (_.isEmpty(pausenmodellSelect.value)) {
		return;
	}
	const pausenmodellSelection = unternehmen.Pausenmodelle.find((pm) => pm._id === pausenmodellSelect.value);
	if (_.isEmpty(pausenmodellSelection)) {
		systemNachrichtService.zeigeKleineNachricht('Pausenmodell nicht im Unternehmen gefunden!', -1);
		return;
	}
	// Speichere Inputdaten ins Pausenmodell
	pausenmodellSelection.Bezeichnung = document.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value;
	// Update der Bezeichnung im Select.
	pausenmodellSelect.options[pausenmodellSelect.selectedIndex].innerText = pausenmodellSelection.Bezeichnung;
	const pausenHTML = document.querySelectorAll('.pausen-liste');
	const pausenStandardNeu = [];
	pausenHTML.forEach((pause) => {
		const pauseObject = {
			Nach: '',
			Dauer: 0,
			Bezahlt: false
		};
		collectValues(pauseObject, 'u-input-pausen-', pause);
		pausenStandardNeu.push(pauseObject);
	});
	pausenmodellSelection.Pausenstandard = pausenStandardNeu;
	updateDebounceUnternehmen(unternehmen);
}

/**
 * Event Handler für den "neu erstellen" Button bei den Pausenmodellen.
 */
async function erstellePausenmodell() {
	const pausenmodellNeu = {};
	const selectedPausenmodell = document.querySelector('[aria-label="u-input-Pausenmodelle"]').value;
	// Speichere Inputdaten ins Pausenmodell
	pausenmodellNeu.Bezeichnung = document.querySelector('[aria-label="u-input-pausen-Bezeichnung"]').value;
	// Falls wir aus einer anderen Pausenmodell erstellen, übernehmen wir den Namen mit Kopie
	if (selectedPausenmodell !== '') {
		pausenmodellNeu.Bezeichnung += ' (Kopie)';
	}
	const pausenHTML = document.querySelectorAll('.pausen-liste');
	const pausenStandardNeu = [];
	pausenHTML.forEach((pause) => {
		const pauseObject = {
			Nach: '',
			Dauer: 0,
			Bezahlt: false,
			Raucherpause: false,
		};
		collectValues(pauseObject, 'u-input-pausen-', pause);
		pausenStandardNeu.push(pauseObject);
	});
	pausenmodellNeu.Pausenstandard = pausenStandardNeu;
	if (pausenmodellNeu.Bezeichnung === '') {
		pausenmodellNeu.Bezeichnung = 'unbenannt';
	}
	const result = await stammdatenService.erstellePausenmodell(pausenmodellNeu);
	// Abbruch bei fehlerhafter Antwort.
	if (_.isEmpty(result)) {
		return;
	}
	// Falls der Vorgang erfolgreich ist, müssen wir das neue Pausenmodell im Select aufnehmen.
	await ladePausenmodelle(stammdatenService.unternehmensobjekt);
	selectPausenmodell(result);
}

/**
 * Erstellt neue Urlaubssperre Dialog
 */
async function urlaubssperreNeu() {
	const dialog = document.getElementById('u-urlaubssperre-dialog');
	const unternehmen = stammdatenService.unternehmensobjekt;
	const betriebSelect = dialog.querySelector('#u-urlaubssperre-betrieb');
	const bereichSelect = dialog.querySelector('#u-urlaubssperre-bereich');
	fuelleSelectOptionen(betriebSelect, unternehmen.Betriebsstaette, '_id', 'BetriebsstaetteName', true, 'alle', 'alle');
	fuelleSelectOptionen(bereichSelect, unternehmen.Bereiche, '_id', 'Bereich', true, 'alle', 'alle');
	const response = await SystemDialogService.instance.displayAsync('u-urlaubssperre-dialog');
	if (response.success) {
		await stammdatenService.neueUrlaubssperre(response.data);
		await ladeUrlaubssperren(stammdatenService.unternehmensobjekt);
	}
}

function confirmUrlaubssperreDialog() {
	const dialog = document.getElementById('u-urlaubssperre-dialog');
	if (![...dialog.querySelectorAll('input')].every((input) => input.checkValidity())) {
		return systemNachrichtService.zeigeKleineNachricht('Bitte alle Felder ausfüllen!');
	}
	const bereichSelect = dialog.querySelector('#u-urlaubssperre-bereich');
	const betriebSelect = dialog.querySelector('#u-urlaubssperre-betrieb');
	SystemDialogService.instance.confirm({
		Titel: dialog.querySelector('#u-urlaubssperre-titel').value,
		Bereich: bereichSelect.options[bereichSelect.selectedIndex].text,
		Betrieb: betriebSelect.options[betriebSelect.selectedIndex].text,
		BereichID: bereichSelect.value,
		BetriebID: betriebSelect.value,
		UrlaubssperreVon: dialog.querySelector('#u-urlaubssperre-datum-von').value,
		UrlaubssperreBis: dialog.querySelector('#u-urlaubssperre-datum-bis').value,
	});
}

/**
 * Rendert Urlaubssperren
 */
async function ladeUrlaubssperren(unternehmen) {
	const urlaubssperren = unternehmen?.Urlaubssperren;
	if (!urlaubssperren) return;
	const template = document.querySelector('[u-urlaubssperre-template]');
	const sperrListe = document.querySelector('#u-urlaubssperre-list');
	sperrListe.innerHTML = '';
	urlaubssperren.forEach((us) => {
		const newRow = template.content.cloneNode(true).children[0];
		newRow.id = us._id;
		displayValues(us, '', newRow, 'u-urlaubssperre-');
		sperrListe.appendChild(newRow);
	});
}

async function entferneUrlaubssperre(thisElement) {
	const urlaubsperreID = thisElement.closest('.u-urlaubssperre-item').id;
	await stammdatenService.entferneUrlaubssperre(urlaubsperreID);
	await ladeUrlaubssperren(stammdatenService.unternehmensobjekt);
}


async function ladeZeitenimport(unternehmen) {
	const e2nKey = unternehmen?.Zeitenimport?.e2nAPIKey;
	if (e2nKey) {
		document.body.querySelector('[aria-label="u-e2nAPIKey"]').value = e2nKey;
		document.getElementById('u-e2n-import-zeitraum').value = window.myVars.aktuellesMonatsDatum.format('YYYY-MM');
		document.getElementById('u-e2n-import-btn').disabled = false;
		document.getElementById('u-e2n-import-zeitraum').disabled = false;
	} else {
		document.getElementById('u-e2n-import-btn').disabled = true;
		document.getElementById('u-e2n-import-zeitraum').disabled = true;
	}
}

async function updateE2NKey(thisElement) {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	unternehmen.Zeitenimport.e2nAPIKey = thisElement.value;
	updateDebounceUnternehmen(unternehmen);
	await ladeZeitenimport(unternehmen);
}

async function importZeitenE2N() {
	const zeitraum = document.getElementById('u-e2n-import-zeitraum').value;
	if (!zeitraum) return systemNachrichtService.zeigeKleineNachricht('Bitte Zeitraum auswählen!', 0);
	await schichtService.importiereZeitenE2N(zeitraum);
}

async function speichereGZEinstellungen() {
	const unternehmen = await stammdatenService.holeUnternehmensdaten();
	const panelEinstellungen = document.getElementById('u-Gutzeitregeln');
	collectValues(unternehmen, 'u-', panelEinstellungen);
	updateDebounceUnternehmen(unternehmen);
}

export {
    ladeZeitregeln,
};
