import stammdatenService from '../services/stammdatenService.js';
import { updateDebounceUnternehmen } from './verwaltung.js';

window.myHandlers = window.myHandlers || {};
window.myHandlers.speichereBetriebsregeln = speichereBetriebsregeln;


function ladeBetriebsregeln() {
    const unternehmen = stammdatenService.unternehmensobjekt;
    document.getElementById('u-betriebsregeln').value = unternehmen?.Betriebsregeln;
}

async function speichereBetriebsregeln(thisElement) {
    const unternehmen = stammdatenService.unternehmensobjekt;
    unternehmen.Betriebsregeln = thisElement.value;
    updateDebounceUnternehmen(unternehmen);
}

export {
    ladeBetriebsregeln
};
