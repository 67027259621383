import _ from 'underscore';
import dayjs from '../../../../shared/services/dayjs.js';
import SystemDialogService from '../../../../shared/services/systemDialogService.js';
import basedataService from '../services/basedataService.js';
import systemNachrichtService from '../services/systemNachrichtService.js';
import { debounce, erstelleOptionGroup } from '../util.js';

const debounceSpeichereVorlage = debounce(async (args) => {
	await speichereVorlage(args[0]);
}, 1000);

window.myHandlers = window.myHandlers || {};
window.myHandlers.confirmVorlageNeuDialog = confirmVorlageNeuDialog;
window.myHandlers.speichereVorlage = speichereVorlage;
window.myHandlers.debounceSpeichereVorlage = debounceSpeichereVorlage;
window.myHandlers.setzeVorlageNeuName = setzeVorlageNeuName;
window.myHandlers.neueVorlage = neueVorlage;
window.myHandlers.editDocName = editDocName;


async function ladeSchriftverkehr() {
	await holeVorlagen();
	await holeMustervorlagen();
	await holeFesteTextmarken();
	await holeTextmarken();
}

async function holeMustervorlagen() {
	const vorlagen = await basedataService.holeVorlagenBasedataProvider();
	const sortedVorlagen = vorlagen.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
	const liste = document.querySelector('#schriftverkehr-mustervorlagen');
	liste.innerHTML = '';
	sortedVorlagen.forEach((item) => {
		const newItem = erstelleMustervorlagenElement(item);
		liste.appendChild(newItem);
	});
}

/**
 * Erstellt oder Updated ein Vorlagendokument
 * - Bei der Erstellung wird zusätzlich nach einem Dateinamen gefragt.
 */
 async function speichereVorlage(nameChange = false) {
	// eine leere File speichern wir nicht.
	if (_.isEmpty(window.myServices.editorService.getCurrentFile())) {
		return;
	}
	try {
		const mdContent = window.myServices.editorService.getMarkdown();
		// Nur Updaten falls es noch Änderungen gibt.
		const oldObject = window.myServices.editorService.getCurrentFile();
		if (oldObject.Inhalt !== mdContent || nameChange) {
			oldObject.Inhalt = mdContent;
			const response = await fetch('/neolohn/api/vorlage', {
				method: 'PUT',
				headers: { 'Content-Type': 'application/json' },
				mode: 'cors',
				body: JSON.stringify(oldObject),
			});
			if (!response.ok) {
				if (response.status === 413) {
					systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
					return;
				}
				systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
				return;
			}
			const result = await response.json();
			window.myServices.editorService.setCurrentFile(result);
			systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
			await holeVorlagen(result._id);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
	}
}

/**
 * markiert die aktuelle Auswahl für bessere Übersicht.
 */
function aktiveVorlageMarkieren(elementId) {
	const items = document.querySelectorAll('.dokument-item-active');
	items.forEach((elem) => elem.classList.remove('dokument-item-active'));
	const auswahl = document.getElementById(elementId);
	if (!auswahl) {
		return;
	}
	auswahl.classList.add('dokument-item-active');
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in der Liste an.
 */
async function holeVorlagen(aktivesElementId) {
	try {
		const response = await fetch('/neolohn/api/vorlage');
		if (response.ok) {
			const result = await response.json();
			const sortedVorlagen = result.sort((a, b) => (a.Name.toUpperCase().localeCompare(b.Name.toUpperCase())));
			const liste = document.querySelector('#schriftverkehr-dokumente-liste');
			liste.innerHTML = '';
			sortedVorlagen.forEach((item) => {
				const newItem = erstelleVorlagenElement(item);
				liste.appendChild(newItem);
			});
		}
		if (aktivesElementId) {
			aktiveVorlageMarkieren(aktivesElementId);
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Dokumenten fehlgeschlagen!', -1);
	}
}

/**
 * Liest ein einzelnes Vorlagen Objekt aus.
 * @param {string} vorlageID
 * @returns vorlagenobjekt
 */
async function fetchVorlage(vorlageID) {
	try {
		const response = await fetch(`/neolohn/api/dokument/md/${vorlageID}`);
		if (!response.ok) {
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Fehler beim Laden der Vorlage!', -1);
		return false;
	}
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleVorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.innerText = `${vorlageObjekt.Name} (${dayjs(vorlageObjekt.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	newItem.id = vorlageObjekt._id;
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		document.getElementById('editor').style.visibility = 'visible';
		// editierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.remove('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
		document.getElementById('schriftverkehr-filename-edit').disabled = false;
	});
	return newItem;
}

/**
 * Erstellt das HTML Element der Vorlage in der Liste der Vorlagen inklusive Event Handler.
 * @param {object} vorlageObjekt
 */
function erstelleMustervorlagenElement(vorlageObjekt) {
	const template = document.querySelector('[schriftverkehr-item-template]');
	const newItem = template.content.cloneNode(true).children[0];
	newItem.innerText = vorlageObjekt.Name;
	newItem.id = vorlageObjekt._id;
	newItem.addEventListener('click', async () => {
		// Speichert die alte ausgewählte Vorlage, beim Wechsel in eine andere
		await speichereVorlage();
		window.myServices.editorService.setMarkdown(vorlageObjekt.Inhalt);
		window.myServices.editorService.setCurrentFile(vorlageObjekt);
		document.getElementById('schriftverkehr-filename-edit').value = vorlageObjekt.Name;
		aktiveVorlageMarkieren(newItem.id);
		// uneditierbarer Inhalt
		const editor = document.getElementById('editor');
		editor.style.visibility = 'visible';
		editor.classList.add('uneditable');
		editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'false');
		document.getElementById('schriftverkehr-filename-edit').disabled = true;
	});
	return newItem;
}

// Neues Dokument soll erstellt werden... dafür wird der Editor Inhalt resettet
async function neueVorlage() {
	await speichereVorlage();
	await fuelleVorlagenSelect();
	const input = await SystemDialogService.instance.displayAsync('vorlage-speichern-dialog');
	if (!input.success) {
		return;
	}
	const name = input.data.name === '' ? 'unbenannte Vorlage' : input.data.name;
	const vorlageID = input.data.vorlageID;
	let mdContent = '';
	// wenn eine Vorlage ausgewählt wurde müssen wir zwischen Mustervorlage und eigener Vorlage unterscheiden.
	if (!_.isEmpty(vorlageID)) {
		const select = document.querySelector('#vorlage-neu-vorlagen');
		const isMustervorlage = select.selectedOptions[0].parentElement.label === 'Mustervorlagen';
		let vorlagenObjekt;
		if (isMustervorlage) {
			const mustervorlagen = await basedataService.holeVorlagenBasedataProvider();
			vorlagenObjekt = mustervorlagen.find((vorlage) => vorlage._id === vorlageID);
		} else {
			vorlagenObjekt = await fetchVorlage(vorlageID);
		}
		if (!_.isEmpty(vorlagenObjekt)) {
			mdContent = vorlagenObjekt.Inhalt;
		}
	}
	const vorlageNeu = await fetchNeueVorlage(name, mdContent);
	window.myServices.editorService.setCurrentFile(vorlageNeu);
	window.myServices.editorService.setMarkdown(vorlageNeu.Inhalt);
	document.getElementById('schriftverkehr-filename-edit').value = vorlageNeu.Name;
	// editierbarer Inhalt
	const editor = document.getElementById('editor');
	editor.style.visibility = 'visible';
	editor.classList.remove('uneditable');
	document.getElementById('schriftverkehr-filename-edit').disabled = false;
	editor.querySelector('.ProseMirror.toastui-editor-contents').setAttribute('contenteditable', 'true');
	systemNachrichtService.zeigeKleineNachricht('Speichern erfolgreich!', 1);
	// neu laden der Dokumente bei Erstellung, um das neue anzuzeigen
	await holeVorlagen(vorlageNeu._id);
	aktiveVorlageMarkieren();
}

/**
 * Sendet die fetch POST Anfrage an den Server, um eine neue Vorlage zu erstellen
 * @param {string} vorlageName
 * @param {string} vorlageInhalt Markdown Content.
 * @returns neues Vorlagenobjekt
 */
async function fetchNeueVorlage(vorlageName, vorlageInhalt) {
	try {
		const response = await fetch('/neolohn/api/vorlage', {
			method: 'POST',
			headers: { 'Content-Type': 'application/json' },
			mode: 'cors',
			body: JSON.stringify({ Inhalt: vorlageInhalt, Name: vorlageName }),
		});
		if (!response.ok) {
			if (response.status === 413) {
				systemNachrichtService.zeigeKleineNachricht('Vorlagedatei zu groß, bitte nur kleine Bilder (weniger als 100KB) einfügen!', -1);
			}
			throw new Error(`HTTP error, status = ${response.status}`);
		}
		const result = await response.json();
		return result;
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Speichern fehlgeschlagen!', -1);
		return false;
	}
}

/**
 * Setzt den Input Namen auf den Vorlagennamen.
 */
function setzeVorlageNeuName(thisElement) {
	if (thisElement.value === '') {
		return;
	}
	const inputName = document.querySelector('#vorlage-neu-name');
	const selectedName = thisElement.selectedOptions[0].innerText;
	inputName.value = `${selectedName} (Kopie)`;
	inputName.focus();
}

/**
 * Click Handler: Übermittlung der Daten für die neue Vorlage aus dem Dialog Feld
 */
async function confirmVorlageNeuDialog() {
	SystemDialogService.instance.confirm({
		name: document.getElementById('vorlage-neu-name').value,
		vorlageID: document.getElementById('vorlage-neu-vorlagen').value
	});
}

/**
 * Lädt die Vorlagen Dokumente vom Server und zeigt sie in dem Select an.
 */
async function fuelleVorlagenSelect() {
	try {
        const select = document.querySelector('#vorlage-neu-vorlagen');
        select.innerHTML = '';
		const response = await fetch('/neolohn/api/vorlage');
		if (!response.ok) {
            const result = await response.text();
            systemNachrichtService.zeigeKleineNachricht(result, 0);
            throw new Error(`HTTP error, status = ${response.status}`);
        }
		const emptyOption = document.createElement('option');
		emptyOption.value = '';
		emptyOption.innerText = 'keine Auswahl';
		select.appendChild(emptyOption);
        const listeEigeneVorlagen = await response.json();
        // Option Gruppe Eigene Vorlagen
        if (listeEigeneVorlagen.length > 0) {
            listeEigeneVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('eigene Vorlagen', listeEigeneVorlagen, '_id', 'Name'));
        }
        const listeStandardVorlagen = await basedataService.holeVorlagenBasedataProvider();
        // Option Gruppe Mustervorlagen
        if (listeStandardVorlagen.length > 0) {
            listeStandardVorlagen.sort((a, b) => a.Name.localeCompare(b.Name));
            select.appendChild(erstelleOptionGroup('Mustervorlagen', listeStandardVorlagen, '_id', 'Name'));
        }
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Vorlagen fehlgeschlagen!', -1);
	}
}

// Fügt den Inhalt der Variable ein in den Editor
function addVariable(textmarke) {
	const editor = document.getElementById('editor');
	// falls wir im nicht edit modus sind, brechen wir ab.
	if (editor.classList.contains('uneditable')) {
		return;
	}
	window.myServices.editorService.replaceCurrentSelection(` ${textmarke} `);
	debounceSpeichereVorlage();
}

// Lädt die Textmarken in die Oberfläche
async function holeTextmarken() {
	try {
		const response = await fetch('/neolohn/api/textmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const textmarken = result.filter((tm) => tm.Collection !== 'Entlohnungsart');
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-flex');
			liste.innerHTML = '';
			textmarken.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			textmarken.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.innerText = item.Textmarke;
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

// Lädt die Textmarken in die Oberfläche
async function holeFesteTextmarken() {
	try {
		const response = await fetch('/neolohn/api/festetextmarken-basedataprovider');
		if (response.ok) {
			const result = await response.json();
			const template = document.querySelector('[schriftverkehr-item-template]');
			const liste = document.querySelector('#schriftverkehr-variablen-fest');
			liste.innerHTML = '';
			result.sort((a, b) => (a.Textmarke.toUpperCase().localeCompare(b.Textmarke.toUpperCase())));
			result.forEach((item) => {
				const newItem = template.content.cloneNode(true).children[0];
				newItem.innerText = item.Textmarke.replaceAll(/(\{|\})/g, '');
				newItem.id = item._id;
				newItem.addEventListener('click', () => {
					addVariable(item.Textmarke);
				});
				liste.appendChild(newItem);
			});
		}
	} catch (error) {
		console.log(error);
		systemNachrichtService.zeigeKleineNachricht('Laden von Variablen fehlgeschlagen!', -1);
	}
}

async function editDocName(thisElement) {
	const currentFile = window.myServices.editorService.getCurrentFile();
	if (_.isEmpty(currentFile) || thisElement.value === '') {
		return;
	}
	window.myServices.editorService.setFileName(thisElement.value);
	document.getElementById(currentFile._id).innerText = `${thisElement.value} (${dayjs(currentFile.DatumUpdate).format('DD.MM.YYYY, HH:mm')})`;
	debounceSpeichereVorlage(true);
}

export {
    ladeSchriftverkehr,
	holeFesteTextmarken,
	holeTextmarken,
	holeVorlagen,
};